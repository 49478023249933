body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Harabara Maris";
}
@font-face {
  font-family: "Harabara Maris";
  src: url("./assets/font/harabara-mais.otf");
}
.container {
  position: relative;
  width: 600px;
  height: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  transform: rotate(0deg) skew(0deg) scale(0.8);
  transition: 0.5s;
}
.container img {
  position: absolute;
  width: 100%;

  transition: 0.5s;
}
.container:hover img:nth-child(4) {
  transform: translate(160px, -160px);
  opacity: 1;
}
.container:hover img:nth-child(3) {
  transform: translate(120px, -120px);
  opacity: 0.8;
}
.container:hover img:nth-child(2) {
  transform: translate(80px, -80px);
  opacity: 0.6;
}
.container:hover img:nth-child(1) {
  transform: translate(40px, -40px);
  opacity: 0.4;
}
.description {
  position: absolute;
  bottom: 10px;
}
